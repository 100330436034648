/* ======================================================================== */
/* 29. SliderProjects */
/* ======================================================================== */
var SliderProjects = function ($slider) {

	if (!$slider.length) {
		return;
	}

	var slider = new Swiper($slider, {
		effect: 'fade',
		fadeEffect: {
			crossFade: true
		},
		speed: 1200,
		simulateTouch: false,
	});

	return slider;

}
