/* ======================================================================== */
/* 16. SectionBlog */
/* ======================================================================== */
var SectionBlog = function ($scope) {

	var $target = $scope.find('.section-blog');

	if (!$target.length) {
		return;
	}

	createSlider();

	function createSlider() {

		var
			$sliderGallery = $target.find('.js-slider-gallery');

		new SliderGallery($sliderGallery);

	}

}
