/* ======================================================================== */
/* 27. SliderProjectBackgrounds */
/* ======================================================================== */
var SliderProjectBackgrounds = function ($slider) {

	if (!$slider.length) {
		return false;
	}

	var slider = new Swiper($slider, {
		effect: 'fade',
		fadeEffect: {
			crossFade: true
		},
		speed: 1200,
		watchSlidesVisibility: true,
		preloadImages: false,
		lazy: {
			loadPrevNext: true
		},
		watchSlidesProgress: true,
		simulateTouch: false,
		nested: true
	});

	return slider;

}
