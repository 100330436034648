'use strict';

var $document = $(document);

/**
 * Default Theme Options
 * Used to prevent errors if there is
 * no data provided from backend
 */

if (typeof window.theme === 'undefined') {
	window.theme = {
		colors: {
			accentPrimary: '#1869ff',
		},
		typography: {
			fontPrimary: 'Open Sans',
			fontSecondary: 'Montserrat'
		}
	}
}

/**
 * ScrollMagic Setup
 */
window.SMController = new ScrollMagic.Controller();
window.SMSceneTriggerHook = 0.85;
window.SMSceneReverse = false;

/**
 * Theme Fonts
 */
window.fontPrimaryObserver = new FontFaceObserver(window.theme.typography.fontPrimary).load();
window.fontSecondaryObserver = new FontFaceObserver(window.theme.typography.fontSecondary).load();

Preloader();

$document.ready(function () {

	new AsideCounters($document);
	new ArticleProject($document);
	new Burger();
	new SectionBlog($document);
	new SectionIntro($document);
	new SectionLatestProjects($document);
	new SectionServices($document);
	new SectionServicesTabs($document);
	new SectionPortfolio($document);
	new ScrollUp();
	new Form();
	new GMap($document);
	new SectionTestimonials($document);
	new MenuOverlay();

});
