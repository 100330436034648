/* ======================================================================== */
/* 3. Burger */
/* ======================================================================== */
var Burger = function () {

	var
		OPEN_CLASS = 'burger_opened',
		$overlay = $('.header__wrapper-overlay-menu');

	var header = new Header();

	$(document).on('click', '.js-burger', function (e) {

		e.preventDefault();

		if (!$overlay.hasClass('in-transition')) {

			var $burger = $(this);

			if ($burger.hasClass(OPEN_CLASS)) {
				$burger.removeClass(OPEN_CLASS);
				header.closeOverlayMenu();
			} else {
				$burger.addClass(OPEN_CLASS);
				header.openOverlayMenu();
			}

		}

	});

}
