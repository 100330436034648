/* ======================================================================== */
/* 1. ArticleProject */
/* ======================================================================== */
var ArticleProject = function ($scope) {

	var $target = $scope.find('.project');

	if (!$target.length) {
		return;
	}

	createSlider();

	function createSlider() {

		var
			$sliderImages = $target.find('.js-slider-images'),
			sliderImages = new SliderImages($sliderImages);

		// start from 2nd slide to not show empty space
		// from the left
		sliderImages.slideTo(1);

	}

}
