/* ======================================================================== */
/* 25. SliderIntroBackgrounds */
/* ======================================================================== */
var SliderIntroBackgrounds = function () {

	var $slider = $('.js-slider-intro-backgrounds');

	if (!$slider.length) {
		return;
	}

	var slider = new Swiper($slider, {
		speed: 1200,
		watchSlidesVisibility: true,
		preloadImages: false,
		lazy: {
			loadPrevNext: true
		},
		direction: 'vertical'
	});

	return slider;

}
