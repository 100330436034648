/* ======================================================================== */
/* 13. lockScroll */
/* ======================================================================== */
function lockScroll(enabled) {

	var
		$body = $('body'),
		$window = $(window),
		LOCK_CLASS = 'body_lock-scroll',
		lastTop = $window.scrollTop();

	if (enabled === true) {

		$body
			.addClass(LOCK_CLASS)
			.css({
				top: -lastTop
			});

	} else {

		var
			offset = parseInt($body.css('top'), 10),
			offsetValue = Math.abs(offset);

		$body
			.removeClass(LOCK_CLASS)
			.css({
				top: 'auto'
			});

		$window.scrollTop(offsetValue);

	}

}
