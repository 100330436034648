/* ======================================================================== */
/* 23. sliderGallery */
/* ======================================================================== */
var SliderGallery = function ($slider) {

	if (!$slider.length) {
		return;
	}

	var slider = new Swiper($slider, {
		autoplay: {
			delay: 6000
		},
		speed: 800,
		preloadImages: false,
		lazy: {
			loadPrevNext: true,
			loadOnTransitionStart: true
		},
		watchSlidesProgress: true,
		watchSlidesVisibility: true,
		pagination: {
			el: '.js-slider-gallery__nav',
			type: 'bullets',
			bulletElement: 'div',
			clickable: true,
			bulletClass: 'slider-nav__dot',
			bulletActiveClass: 'slider-nav__dot_active'
		}
	});

	return slider;

}
