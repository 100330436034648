/* ======================================================================== */
/* 20. SectionServices */
/* ======================================================================== */
var SectionServices = function ($scope) {

	var $target = $scope.find('.section-services');

	if (!$target.length) {
		return;
	}

	var
		$servicesHover = $target.find('.js-service-hover'),
		$backgrounds = $target.find('.js-service-hover__background');

	if ($servicesHover.length && $backgrounds.length) {
		hoverBackgrounds();
	}

	function hoverBackgrounds() {

		TweenMax.set($backgrounds, {
			autoAlpha: 0,
			scale: 1.1
		});

		$servicesHover
			.on('mouseenter touchstart', function (e) {

				var
					postId = $(this).data('services-post-id'),
					$targetBackground = $backgrounds.filter('[data-services-background-for="' + postId + '"]'),
					$otherBackgrounds = $backgrounds.not($targetBackground);

				TweenMax.to($otherBackgrounds, 0.6, {
					autoAlpha: 0,
					scale: 1.1,
					ease: Expo.easeInOut,
				});

				TweenMax.to($targetBackground, 0.6, {
					autoAlpha: 1,
					scale: 1,
					ease: Expo.easeInOut
				});

			}).on('mouseleave touchend', function (e) {

				TweenMax.to($backgrounds, 0.6, {
					autoAlpha: 0,
					scale: 1.1,
					ease: Expo.easeInOut,
				});

			});

	}

}
