/* ======================================================================== */
/* 12. ScrollDown */
/* ======================================================================== */
var ScrollDown = function ($target) {

	var $section = $target.closest('.section-fullheight');

	if (!$target.length || !$section.length) {
		return;
	}

	var
		offset = $section.height(),
		$body = $('html, body');

	$target.on('click', function (e) {

		e.preventDefault();
		$body.animate({
			scrollTop: offset
		}, 600, 'swing');

	});

}
