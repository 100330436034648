/* ======================================================================== */
/* 31. SliderTestimonials */
/* ======================================================================== */
var SliderTestimonials = function ($slider) {

	if (!$slider.length) {
		return;
	}

	var slider = new Swiper($slider, {
		autoHeight: true,
		speed: 800,
		lazy: {
			loadPrevNext: true,
			loadOnTransitionStart: true
		},
		pagination: {
			el: $slider.find('.js-slider-testimonials__nav'),
			type: 'bullets',
			bulletElement: 'div',
			clickable: true,
			bulletClass: 'slider-nav__dot',
			bulletActiveClass: 'slider-nav__dot_active',
		},
	});

	return slider;

}
