/* ======================================================================== */
/* 17. SectionIntro */
/* ======================================================================== */
var SectionIntro = function ($scope) {

	var $target = $scope.find('.section-intro');

	if (!$target.length) {
		return;
	}

	var
		$sliderContent = $target.find('.js-slider-intro-content'),
		$sliderBackgrounds = $target.find('.js-slider-intro-backgrounds'),
		sliderContent = new SliderIntroContent($sliderContent),
		sliderBackgrounds = new SliderIntroBackgrounds($sliderBackgrounds),
		$header = $('.header'),
		$contentContainer = $target.find('.section-intro__wrapper-content'),
		$sectionInner = $target.find('.section-fullheight__inner'),
		$curtain1 = $target.find('.section-intro__diagonal-curtain-1'),
		$curtain2 = $target.find('.section-intro__diagonal-curtain-2'),
		$scrollDown = $target.find('.js-scroll-down');

	if (sliderContent.slides.length <= 1) {
		sliderContent.destroy(true, true);
		sliderBackgrounds.destroy(true, true);
	} else {
		chainSliders();
	}

	new ScrollDown($scrollDown);
	// offsetHeader();
	// prepare();
	// animate();

	function chainSliders() {

		if (sliderContent && sliderBackgrounds) {

			sliderContent.controller.control = sliderBackgrounds;
			sliderBackgrounds.controller.control = sliderContent;

		}

	}

	function offsetHeader() {

		if ($header.length) {

			var offset = $header.height();

			if ($header.hasClass('header_absolute')) {
				$contentContainer.css({
					paddingTop: offset + 'px'
				});

			} else {

				$sectionInner.css({
					minHeight: 'calc(100vh - ' + offset + 'px)'
				});

			}

		}

	}

	function prepare() {

		TweenMax.set([$curtain1, $curtain2], {
			x: '-100%',
			y: '-100%'
		});

	}

	function animate() {
		TweenMax.staggerTo([$curtain1, $curtain2], 2, {
			x: '0%',
			y: '0%',
			ease: Expo.easeInOut
		}, 0.3);
	}

}
