/* ======================================================================== */
/* 5. Form */
/* ======================================================================== */
var Form = function () {

	var $form = $('.js-ajax-form');

	if (!$form.length) {
		return;
	}

	$form.on('submit', function (e) {
		e.preventDefault();
	});

	validateForm();

	function validateForm() {

		$form.validate({
			errorElement: 'span',
			errorPlacement: function (error, element) {
				error.appendTo(element.parent()).addClass('form-control__error');
			},
			submitHandler: function (form) {
				ajaxSubmit();
			}
		});

	}

	function ajaxSubmit() {

		$.ajax({
			type: $form.attr('method'),
			url: $form.attr('action'),
			data: $form.serialize()
		}).done(function () {
			alert($form.attr('data-message-success'));
			$form.trigger('reset');
			floatLabels();
		}).fail(function () {
			alert($form.attr('data-message-error'));
		});
	}

}
