/* ======================================================================== */
/* 26. SliderIntroContent */
/* ======================================================================== */
var SliderIntroContent = function ($slider) {

	if (!$slider.length) {
		return;
	}

	var slider = new Swiper($slider, {
		speed: $slider.data('speed') || 1200,
		autoplay: {
			enabled: $slider.data('autoplay-enabled') || false,
			delay: $slider.data('autoplay-delay') || 6000,
		},
		pagination: {
			el: '.js-slider-intro-content__nav',
			type: 'bullets',
			bulletElement: 'div',
			clickable: true,
			bulletClass: 'slider-nav__dot',
			bulletActiveClass: 'slider-nav__dot_active'
		},
		simulateTouch: false
	});

	return slider;

}
