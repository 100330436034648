/* ======================================================================== */
/* 7. Grid */
/* ======================================================================== */
var Grid = function ($grid) {

	var $target = $grid;

	if (!$target.length) {
		return;
	}


	var masonryGrid = $target.masonry({
		itemSelector: '.js-grid__item',
		columnWidth: '.js-grid__sizer',
		percentPosition: true
	});

	$target.find('.lazy-masonry').Lazy({
		afterLoad: function () {
			masonryGrid.masonry('layout');
		}
	});

	return masonryGrid;

}
