/* ======================================================================== */
/* 24. sliderImages */
/* ======================================================================== */
var SliderImages = function ($slider) {

	if (!$slider.length) {
		return;
	}

	var slider = new Swiper($slider, {
		speed: 800,
		parallax: true,
		preloadImages: false,
		lazy: {
			loadPrevNext: true,
			loadOnTransitionStart: true
		},
		watchSlidesProgress: true,
		watchSlidesVisibility: true,
		centeredSlides: true,
		slidesPerView: 1.75,
		spaceBetween: 30,
		pagination: {
			el: '.js-slider-images__nav',
			type: 'bullets',
			bulletElement: 'div',
			clickable: true,
			bulletClass: 'slider-nav__dot',
			bulletActiveClass: 'slider-nav__dot_active'
		},
		navigation: {
			nextEl: '.js-slider-images__next',
			prevEl: '.js-slider-images__prev',
		},
		breakpoints: {
			1400: {
				slidesPerView: 1.33,
				spaceBetween: 15
			},
			1200: {
				slidesPerView: 1.2,
				spaceBetween: 15
			}
		}
	});

	return slider;

}
