/* ======================================================================== */
/* 22. SectionTestimonials */
/* ======================================================================== */
var SectionTestimonials = function ($scope) {

	var $target = $scope.find('.section-testimonials');

	if (!$target.length) {
		return;
	}

	var $slider = $target.find('.js-slider-testimonials');

	createSlider();

	function createSlider() {

		if (!$slider.length) {
			return;
		}

		var slider = new SliderTestimonials($slider);

	}

}
