/* ======================================================================== */
/* 32. Tabs */
/* ======================================================================== */
var Tabs = function ($tabs) {

	if (!$tabs.length) {
		return;
	}

	var
		self = this,
		itemClass = '.js-tabs__item',
		$items = $(itemClass),
		activeItemClass = 'tabs__item_active';

	this.$tabs = $tabs;
	this.$items = $items;

	bindEvents();
	updateLinePosition();

	function bindEvents() {

		$(document)
			.on('mouseenter', itemClass, function () {

				updateLinePosition($(this));

			})
			.on('mouseleave', itemClass, function () {

				updateLinePosition($items.filter('.' + activeItemClass));

			})
			.on('click', itemClass, function () {

				var $el = $(this);

				$items.removeClass(activeItemClass);
				$el.addClass(activeItemClass);
				updateLinePosition($el);

			});

	}

	function updateLinePosition($target) {

		var
			$line = self.$tabs.find('.js-tabs__underline');

		if (!$line.length) {
			return;
		}

		if (!$target || !$target.length) {

			TweenMax.to($line, 0.6, {
				width: 0
			});

		} else {

			var
				headingWidth = $target.find('h4').innerWidth(),
				headingPos = $target.find('h4').position().left,
				colPos = $target.position().left;

			TweenMax.to($line, 0.6, {
				ease: Expo.easeInOut,
				width: headingWidth,
				x: headingPos + colPos,
			});

		}

	}

	this.setActiveTab = function (index) {

		var $target = $items.eq(index);
		if (!$target) {
			return;
		}

		$items.removeClass(activeItemClass);
		$target.addClass(activeItemClass);
		updateLinePosition($target, self.$tabs);

	};

}
