/* ======================================================================== */
/* 9. lazyLoad */
/* ======================================================================== */
function lazyLoad($scope, $elements, cb) {

	if (!$scope) {
		var $scope = $(document);
	}

	if (!$elements) {
		var $elements = $scope.find('.lazy');
	}

	$elements.Lazy({
		afterLoad: function (el) {

			if ($(el).hasClass('jarallax')) {
				$(el).jarallax();
			}

			if (cb !== undefined) {
				cb();
			}

		}
	});

}
