/* ======================================================================== */
/* 8. Header */
/* ======================================================================== */
var Header = function () {

	var
		$body = $('body'),
		$overlay = $('.header__wrapper-overlay-menu');

	if (!$overlay.length) {
		return;
	}

	var
		$pageWrapper = $('.page-wrapper'),
		$menu = $('.js-menu-overlay'),
		$menuLinks = $overlay.find('.menu-overlay > li > a .menu-overlay__item-wrapper'),
		$linksTop = $menu.find('> li > a'),
		$submenu = $overlay.find('.menu-overlay .sub-menu'),
		$submenuButton = $overlay.find('.js-submenu-back'),
		$submenuLinks = $submenu.find('> li > a .menu-overlay__item-wrapper'),
		$curtain1 = $overlay.find('.header__diagonal-curtain-1'),
		$curtain2 = $overlay.find('.header__diagonal-curtain-2'),
		$background = $overlay.find('.header__wrapper-background'),
		$backgrounds = $overlay.find('.header__background'),
		$overlayBackgrounds = $overlay.find('.header__overlay'),
		$overlayWidgets = $overlay.find('.header__wrapper-overlay-widgets');

	setOverlayMenu();

	if ($backgrounds.length) {
		hoverBackgrounds();
	}

	function setOverlayMenu() {

		TweenMax.set([$overlay, $overlayBackgrounds], {
			autoAlpha: 0
		});

		TweenMax.set([$menuLinks, $submenuLinks], {
			y: '100%'
		});

		TweenMax.set($overlayWidgets, {
			y: '10px',
			autoAlpha: 0
		});

		TweenMax.set([$submenu, $submenuButton], {
			autoAlpha: 0,
			x: '10px'
		});

		TweenMax.set($backgrounds, {
			autoAlpha: 0,
			scale: 1.1
		});

		TweenMax.set($curtain1, {
			x: '-50%',
		});

		TweenMax.set($curtain2, {
			x: '50%',
		});

	}

	this.closeOverlayMenu = function () {

		lockScroll(false);

		var tl = new TimelineMax();

		tl.timeScale(1.5);

		tl
			// .set($body, {
			// 	className: '-=body_lock-scroll'
			// })
			.set($overlay, {
				className: '+=in-transition',
			})
			.set($pageWrapper, {
				// display: 'block',
				className: '-=page-wrapper_hidden',
			})
			.to([$menuLinks, $submenuLinks], 0.6, {
				y: '-100%',
				ease: Power4.easeIn
			})
			.to($overlayWidgets, 0.6, {
				y: '-10px',
				autoAlpha: 0
			}, '-=0.3')
			.to($submenuButton, 0.6, {
				x: '-10px',
				autoAlpha: 0
			}, 0)
			.set($overlayBackgrounds, {
				autoAlpha: 0
			})
			.set($background, {
				backgroundColor: 'transparent'
			})
			.to($backgrounds, 1, {
				autoAlpha: 0,
				scale: 1.1,
				ease: Power4.easeOut,
			}, 0.4)
			.to($curtain1, 1, {
				x: '-50%',
				right: '20%',
				ease: Expo.easeInOut,
			}, 0.6)
			.to($curtain2, 1, {
				x: '50%',
				left: '80%',
				ease: Expo.easeInOut,
			}, 0.6)
			.set($overlay, {
				className: '-=in-transition'
			})
			.add(function () {
				setOverlayMenu();
			});

	};

	this.openOverlayMenu = function () {

		lockScroll(true);

		var tl = new TimelineMax();

		tl
			// .set($body, {
			// 	className: '+=body_lock-scroll'
			// })
			.set($overlay, {
				className: '+=in-transition',
				autoAlpha: 1
			})
			.to([$curtain1, $curtain2], 1, {
				x: '0%',
				ease: Expo.easeInOut,
			})
			.to($overlayBackgrounds, 0.3, {
				autoAlpha: 1
			})
			.staggerTo($menuLinks, 0.6, {
				y: '0%',
				ease: Power4.easeOut,
			}, .075, 0.8)
			.to($overlayWidgets, 0.6, {
				y: '0px',
				autoAlpha: 1
			}, '-=0.3')
			.to($background, 0.3, {
				backgroundColor: '#181818'
			})
			.set($pageWrapper, {
				// display: 'none',
				className: '+=page-wrapper_hidden',
			})
			.set($overlay, {
				className: '-=in-transition'
			});

	};

	function hoverBackgrounds() {

		$linksTop
			.on('mouseenter click', function () {

				if (Modernizr.mq('(min-width: 768px)')) {

					var
						postId = $(this).data('post-id'),
						$targetBackground = $backgrounds.filter('[data-header-background-for="' + postId + '"]');

					if (!$targetBackground.length) {
						return;
					}

					TweenMax.to($curtain1, 0.6, {
						right: '50%',
						ease: Expo.easeInOut
					});

					TweenMax.to($curtain2, 0.6, {
						left: '100%',
						ease: Expo.easeInOut
					});

					TweenMax.to($targetBackground, 0.6, {
						autoAlpha: 1,
						scale: 1,
						ease: Expo.easeInOut,
					});

				}

			})
			.on('mouseleave', function () {

				var $openedSubmenus = $('.sub-menu.opened');

				if ($openedSubmenus.length) {
					return;
				}

				var
					postId = $(this).data('post-id'),
					$targetBackground = $backgrounds.filter('[data-header-background-for="' + postId + '"]');

				TweenMax.to($curtain1, 0.6, {
					right: '20%',
					ease: Expo.easeInOut
				});

				TweenMax.to($curtain2, 0.6, {
					left: '80%',
					ease: Expo.easeInOut
				});

				TweenMax.to($targetBackground, 0.6, {
					autoAlpha: 0,
					scale: 1.1,
					ease: Expo.easeInOut,
				});

			});

	}

}
