/* ======================================================================== */
/* 18. SectionLatestProjects */
/* ======================================================================== */
var SectionLatestProjects = function ($scope) {

	var $target = $scope.find('.section-latest-projects');

	if (!$target.length) {
		return;
	}

	var
		$slider = $target.find('.js-slider-projects'),
		$inners = $target.find('.section-latest-projects__inner'),
		$tabs = $target.find('.js-tabs');

	bindSliderTabs();
	createInnerSliders();

	function bindSliderTabs() {

		if (!$slider.length || !$tabs.length) {
			return;
		}

		var slider = new SliderProjects($slider);
		var tabs = new Tabs($tabs);

		// initial set
		tabs.setActiveTab(slider.activeIndex);

		// handle slides change
		slider.on('slideChange', function () {
			tabs.setActiveTab(this.activeIndex);
		});

		// handle clicks on tabs
		tabs.$items.on('click', function () {
			var index = $(this).index();
			slider.slideTo(index);
		});

	}

	function createInnerSliders() {

		if (!$inners.length) {
			return;
		}

		$inners.each(function () {

			var
				$el = $(this),
				$sliderContent = $el.find('.js-slider-project-content'),
				$sliderBackgrounds = $el.find('.js-slider-project-backgrounds'),
				sliderContent = new SliderProjectContent($sliderContent),
				sliderBackgrounds = new SliderProjectBackgrounds($sliderBackgrounds);

			chainSliders();

			function chainSliders() {

				if (sliderContent && sliderBackgrounds) {

					sliderContent.controller.control = sliderBackgrounds;
					sliderBackgrounds.controller.control = sliderContent;

				}

			}
		});

	}

}
