/* ======================================================================== */
/* 15. ScrollUp */
/* ======================================================================== */
var ScrollUp = function () {

	var
		$target = $('.js-scroll-up'),
		tl = new TimelineMax();

	prepare();
	animate();
	scrollUp();

	function prepare() {

		if (!$target.length) {
			return;
		}

		TweenMax.set($target, {
			autoAlpha: 0,
			y: '20px'
		});

	}

	function animate() {

		if (!$target.length) {
			return;
		}

		var
			offset = $(window).height(),
			$trigger = $('body');

		tl.to($target, 0.6, {
			autoAlpha: 1,
			y: '0px'
		});

		new $.ScrollMagic.Scene({
				reverse: true,
				triggerElement: $trigger,
				offset: offset
			})
			.setTween(tl)
			.addTo(window.SMController);

	}

	function scrollUp() {

		if (!$target.length) {
			return;
		}

		$target.on('click', function (e) {

			e.preventDefault();

			$('html, body').stop().animate({
				scrollTop: 0
			}, 800, 'swing');

		});

	}

}
