/* ======================================================================== */
/* 14. createOSScene */
/* ======================================================================== */
function createOSScene($el, tl, $customTrigger, noReveal) {

	var $trigger = $el;

	if ($customTrigger && $customTrigger.length) {
		$trigger = $customTrigger;
	}

	if (!noReveal) {
		// reveal hidden element first
		tl.add([TweenMax.set($el, {
			autoAlpha: 1
		})], 'start');
	}

	new $.ScrollMagic.Scene({
			triggerElement: $trigger,
			triggerHook: window.SMSceneTriggerHook,
			reverse: window.SMSceneReverse
		})
		.setTween(tl)
		.addTo(window.SMController);

}
