/* ======================================================================== */
/* 11. Preloader */
/* ======================================================================== */
function Preloader() {

	var
		tl = new TimelineMax(),
		$pageWrapper = $('.page-wrapper'),
		$preloader = $('.preloader'),
		$bar = $preloader.find('.preloader__progress'),
		$fill = $preloader.find('.preloader__progress-fill'),
		$curtains = $preloader.find('.preloader__curtain'),
		$curtains = $curtains.get().reverse();

	function finish() {

		return new Promise(function (resolve) {

			Promise.all([window.fontPrimaryObserver, window.fontSecondaryObserver]).then(function () {

				tl
					.clear()
					.to($fill, 0.6, {
						scaleX: 1,
						transformOrigin: 'left center',
						ease: Expo.easeOut
					})
					.to($bar, 0.6, {
						autoAlpha: 0,
						y: '-30px'
					})
					.staggerTo($curtains, 1, {
						x: '-100%',
						ease: Expo.easeInOut,
					}, 0.1, '-=0.6')
					.set($preloader, {
						autoAlpha: 0
					})
					.add(function () {
						return resolve();
					}, '-=0.6');

			});

		})

	}

	function drawLoading() {

		tl
			.to($fill, 20, {
				scaleX: 1,
				transformOrigin: 'left center',
				ease: SlowMo.ease.config(0.7, 0.7, false)
			});

	}

	function animateUnload() {

		window.onbeforeunload = function () {
			$pageWrapper.addClass('page-wrapper_hidden');
			return;
		};

	}

	return new Promise(function (resolve, reject) {

		animateUnload();
		drawLoading();
		objectFitImages();
		$('.jarallax-video').jarallax();
		lazyLoad($document);
		$pageWrapper.removeClass('page-wrapper_hidden');

		if (!$preloader.length) {
			return resolve();
		} else {
			finish().then(function () {
				return resolve();
			});
		}

	});

}
