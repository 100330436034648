/* ======================================================================== */
/* 2. AsideCounters */
/* ======================================================================== */
var AsideCounters = function ($scope) {

	var $target = $scope.find('.aside-counters');

	if (!$target) {
		return;
	}

	var $counter = $scope.find('.js-counter');

	$counter.each(function () {

		new Counter($(this));

	});

}
