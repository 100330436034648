/* ======================================================================== */
/* 30. SliderServices */
/* ======================================================================== */
var SliderServices = function ($slider) {

	if (!$slider.length) {
		return;
	}

	var slider = new Swiper($slider, {
		speed: 800,
		autoHeight: true
	});

	return slider;

}
