/* ======================================================================== */
/* 21. sectionServicesTabs */
/* ======================================================================== */
var SectionServicesTabs = function ($scope) {

	var $target = $scope.find('.section-services-tabs');

	if (!$target.length) {
		return;
	}

	var
		$slider = $target.find('.js-slider-services'),
		$tabs = $target.find('.js-tabs');

	bindSliderTabs();

	function bindSliderTabs() {

		if (!$slider.length || !$tabs.length) {
			return;
		}

		var slider = new SliderServices($slider);
		var tabs = new Tabs($tabs);

		// initial set
		tabs.setActiveTab(slider.activeIndex);

		// handle slides change
		slider.on('slideChange', function () {
			tabs.setActiveTab(this.activeIndex);
		});

		$(window).on('resize', function () {
			tabs.setActiveTab(this.activeIndex);
		})

		// handle clicks on tabs
		tabs.$items.on('click', function () {
			var index = $(this).index();
			slider.slideTo(index);
		});

	}

}
