/* ======================================================================== */
/* 19. SectionPortfolio */
/* ======================================================================== */
var SectionPortfolio = function ($scope) {

	var $target = $scope.find('.section-portfolio');

	if (!$target.length) {
		return;
	}

	var $filter = $target.find('.js-filter');
	var $grid = $target.find('.js-grid');

	bindGridTabs();

	function bindGridTabs() {

		if (!$filter.length) {
			return;
		}

		var filter = new Tabs($filter);
		var grid = new Grid($grid);

		grid.isotope();
		setTimeout(function () {
			filter.setActiveTab(0);
		}, 100);

		filter.$items.on('click', function (e) {

			e.preventDefault();

			var filterBy = $(this).attr('data-filter');

			grid.isotope({
				filter: filterBy
			});

		});

	}

}
