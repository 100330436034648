/* ======================================================================== */
/* 28. SliderProjectContent */
/* ======================================================================== */
var SliderProjectContent = function ($slider) {

	if (!$slider.length) {
		return false;
	}

	var slider = new Swiper($slider, {
		speed: 1200,
		preloadImages: false,
		lazy: {
			loadPrevNext: true,
			loadOnTransitionStart: true
		},
		watchSlidesProgress: true,
		watchSlidesVisibility: true,
		pagination: {
			el: $slider.find('.js-slider-project-content__nav'),
			type: 'bullets',
			bulletElement: 'div',
			clickable: true,
			bulletClass: 'slider-nav__dot',
			bulletActiveClass: 'slider-nav__dot_active',
		},
		navigation: {
			nextEl: '.js-slider-project-content__next',
			prevEl: '.js-slider-project-content__prev',
		},
		parallax: true,
		nested: true,
		breakpoints: {
			767: {
				parallax: false
			}
		}
	});

	return slider;

}
